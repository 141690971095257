/*
 * @Author: jiang
 * @Date: 2021-06-12 17:39:11
 * @Description: 待办相关
 */

import request from '../axios'

/**
 * 任务统计
 */
export function getBacklogCount(deptId, userId) {
  return request({
    url: `/api/index/header/count/department/${deptId}/user/${userId}`,
  })
}

export function getBacklogPage(id, params = {}, range = 'dept') {
  if (range === 'dept') {
    return request({
      url: `/api/departments/${id}/tasks`,
      params,
    })
  } else {
    return request({
      url: '/api/users/tasks/list',
      params: {
        ...params,
        user_id: id,
      },
    })
  }
}

// export function getDeptBacklogPage(params){

// }

// export function getUserBacklogPage(params){

// }

/**
 * 待办详情
 * @param { string } id
 */
export function getBacklogDetail(id) {
  return request({
    url: `/api/tasks/${id}`,
  })
}

/**
 * 获取待办的落实情况
 * @param { string } id 待办id
 */
export function getBacklogAction(id) {
  return request({
    url: `/api/tasks/${id}/actions`,
  })
}

/**
 * 待办转发
 */
export function updateBacklogForward(params) {
  return request({
    method: 'post',
    url: '/api/forward/task',
    data: params,
  })
}

/**
 * 任务落实
 */
export function createBacklogAction(params) {
  params.location = 'web 端提交'
  return request({
    method: 'post',
    url: '/api/actions',
    data: params,
  })
}

/**
 * 修改任务落实
 */
export function updateBacklogAction(id, params) {
  params.location = 'web 端提交'
  return request({
    method: 'post',
    url: `/api/actions/${id}`,
    data: params,
  })
}
